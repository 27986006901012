import { Button } from "@chakra-ui/button"
import { useColorMode } from "@chakra-ui/color-mode"
import { MoonIcon, SunIcon } from "@chakra-ui/icons"
import { Stack } from "@chakra-ui/layout"
import { UnorderedList } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/layout"
import React from "react"
import { globalHistory } from "@reach/router"
import { Link } from "gatsby"
import { PageProps } from "gatsby"

interface IHeader extends PageProps {
    isVisible: boolean;
}

const Header: React.FC<IHeader> = ({isVisible, location}) => {
    const { colorMode, toggleColorMode } = useColorMode()
    const currentURL = globalHistory.location.pathname;
    const projectPageRegex = new RegExp(/\/tell-me-more[\/A-Za-z0-9]{2,}/)
    if (isVisible) {
        return (
        <Box as="header" role="navigation"
            mx="auto"
            maxW="7xl"
            my={4}
            px={{ base: 4, md: 8 }}
            paddingLeft={{base: 0, md: 8 }}
        >
            <Stack>
                <Stack direction="row" spacing="4" align="center" justify={currentURL === '/' ? "flex-end" : "space-between"}>
                    {
                        currentURL !== '/' && 
                        <UnorderedList>
                            <Link to="/">
                                <Button variant="ghost">
                                    Home 🏡
                                </Button>
                            </Link>
                            <Link to="/say-hello">
                                <Button variant="ghost">
                                    Say hello 👋
                                </Button>
                            </Link>
                            {
                                projectPageRegex.test(currentURL)
                                 && <Link to="/tell-me-more">
                                        <Button variant="ghost">
                                            Back to projects 👨‍💻
                                        </Button>
                                    </Link>
                            }
                        </UnorderedList>
                    }
                    <Button onClick={toggleColorMode} variant="ghost">
                        {
                            colorMode === 'light' ? <MoonIcon /> : <SunIcon />
                        }
                    </Button>
                </Stack>
            </Stack>
        </Box>
        )
    }
    return <></>
}

export default Header

Header.defaultProps = {
    isVisible: true
}
