import React from "react"
import { Link } from "gatsby"
import { Flex, Stack, Grid, Box, Text, Heading } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { useColorModeValue as mode } from "@chakra-ui/color-mode";
import Layout from "../components/layout";
import InterestsCard from "../components/interests-card"

const IndexPage = () => {

  const color = mode("gray.800", "white")
  const boxColor = mode("black", "black")
  const buttonBg = mode("blackAlpha.900", "whiteAlpha.300");
  const buttonHover = mode("blackAlpha.800", "whiteAlpha.400");

  return (
    <Layout title="Hi, I'm Syed! 👋" icon="🏡">
      <Flex as="div"
          direction="column"
          placeContent="center"
          width="1080px"
          maxWidth="100%"
          minHeight="90vh"
          marginLeft="auto"
          marginRight="auto"
          paddingTop={6}
          paddingBottom={6}
          paddingLeft={3}
          paddingRight={3}
          >
            <Stack as="div"
              textAlign="center"
              marginBottom={{base: 8, md: 24}}
            >
              <Heading as="h1" role="heading"
                color={color}
                fontWeight={400}
                fontSize="clamp(24px, 28px + 2vw, 4rem)"
                marginBottom={0}
                marginTop={0}
              >
                <Text as="span">Hi, I'm </Text>
                <Text as="span"
                  fontWeight="500"
                > Syed! 
                </Text>
                <Text as="span" role="img" aria-label="Waving hand emoji">
                👋
                </Text>
              </Heading>
              <Text as="p"
                fontSize="clamp(1.05rem, 0.5rem + 2vw, 1.25rem)"
                fontStyle="italic"
                marginBottom={32}
                marginTop={0.5}
              >
                And here's a little bit about me...
              </Text>
            </Stack>
        <Grid gridTemplateColumns={{base: "repeat(1, minmax(0, 1fr))", md: "repeat(autofit, minmax(200px, 1fr))", lg: "repeat(2, minmax(0, 1fr))"}} placeContent="center" textAlign="center">
          
            <InterestsCard
              placement={1}
              title="What I love"
              interest={{
                emoji: "🍕🍕🍕",
                label: "Pizza emooji",
              }}
              border={{
                right: {lg: "1px solid black"},
                left: "none",
                top: "none",
                bottom: {base: "1px solid black"}
              }}
            
            ></InterestsCard>

          
            <InterestsCard
              placement={2}
              title="What I enjoy"
              interest={{
                emoji: "🧑‍💻🧑‍💻🧑‍💻",
                label: "Technologist emooji",
              }}
              border={{
                right: "none",
                left: "none",
                top: "none",
                bottom: {base: "1px solid black"}
              }}
            
            ></InterestsCard>

          
            <Stack as="div" padding={6} background="antiquewhite" borderRight={{lg: "1px solid black"}}>
              <Text as="p" textTransform="uppercase" marginBottom="6" fontWeight="600" fontSize="xl" background="transparent" color={boxColor}>
              What I <Text as="del" background="transparent" color={boxColor}>love</Text><Text as="span" background="transparent" color={boxColor}> </Text><Text as="del" background="transparent" color={boxColor}>hate</Text><Text as="mark" background="transparent" color={boxColor}> both</Text>
              </Text>
              <Text as="span" role="img" aria-label="Football and video game controller emojis" fontSize="2xl" background="transparent" color={boxColor}>
              FIFA ⚽️🎮
              </Text>
            </Stack>

          
          <InterestsCard
            placement={4}
            title="Other interests"
            interest={{
              emoji: "🍫🍫🍫",
              label: "Chocolate bar emooji",
            }}
            border={{
              right: "none",
              left: "none",
              top: {base: "1px solid black", lg: "none"},
              bottom: "none"
            }}
          
          ></InterestsCard>
          
        </Grid>
        <Box textAlign="center">
        <Link to="/tell-me-more">
            <Button  as="button"
                color="white"
                marginTop="8"
                display="inline-flex"
                width="auto"
                minWidth="8"
                borderRadius="3xl"
                paddingLeft="8"
                paddingRight="8"
                paddingTop="0"
                paddingBottom="0"
                background={buttonBg}
                _hover={{bg: buttonHover}}
                _active={{bg: buttonHover}}
            >
                Tell me more
            </Button>
          </Link>
        </Box>
      </Flex>
    </Layout>
  )
}

export default IndexPage
