import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Flex, Heading, Text } from "@chakra-ui/layout"
import { Image } from "@chakra-ui/react"

import Layout from "../components/layout"


const WooHoo: React.FC<any> = () => {
    const woohooAsset = useStaticQuery(graphql`
        query {
            contentfulAsset(title: {eq: "Woohoo"}) {
                file {
                    url
                }
            }
        }
    `)


    return (
        <Layout
            title="Woohoo! Message received"
            icon="🎉"
        >
            <Flex
                flexDirection={{base: "column", lg: "row"}}
                alignItems="center"
                placeContent="center"
                width="1080px"
                maxWidth="100%"
                minHeight="90vh"
                marginLeft="auto"
                marginRight="auto"
                paddingTop={6}
                paddingBottom={6}
                paddingLeft={3}
                paddingRight={3}
            >
                <Image 
                        boxSize={96}
                        marginTop={10}
                        src={woohooAsset.contentfulAsset.file.url}
                />
                <Heading textAlign="center">
                    <Text as="h1" fontSize="5xl">
                        Woohoo! Your message has been received
                        <Text as="span" role="img" aria-label="Party popper emoji" marginX={2}>
                        🎉
                    </Text>
                    </Text>
                </Heading>

            </Flex>
        </Layout>
    )
}

export default WooHoo
