import { FormControl, FormLabel, FormHelperText } from "@chakra-ui/form-control"
import { Button, Grid, Image, Skeleton, Textarea } from "@chakra-ui/react"
import { Flex, VStack, Box, Stack, Heading, Text } from "@chakra-ui/layout"
import { Input } from "@chakra-ui/react"
import React from "react"


import Layout from "../components/layout"
import { navigate } from "gatsby"
import { useStaticQuery } from "gatsby"
import { graphql } from "gatsby"

interface ISayHello {}

const encode = (data: { [x: string]: string | number | boolean }) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
}

const SayHello: React.FC<ISayHello> = () => {
    const [state, setState] = React.useState({})

    const sayHelloAsset = useStaticQuery(graphql`
        query {
            contentfulAsset(title: { eq: "Say hello"}) {
                file {
                    url
                }
            }
        }
    `)

    const handleChange = (e) => {
      setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleSubmit = (event: { preventDefault: () => void; target: any }) => {
        event.preventDefault()
        const form = event.target
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
              'form-name': form.getAttribute('name'),
              ...state,
            }),
          })
            .then(() => navigate(form.getAttribute('action')))
            .catch((error) => alert(error))
    }

    return (
        <Layout
            title="Say hello! 👋"
            icon="✉️"
        >
            <Flex
                flexDirection="column"
                placeContent="center"
                width="1080px"
                maxWidth="100%"
                minHeight="90vh"
                marginLeft="auto"
                marginRight="auto"
                paddingTop={6}
                paddingBottom={6}
                paddingLeft={3}
                paddingRight={3}
            >
                <Box display={{base: "none", md: "block"}} marginY={8}>
                    <Heading textAlign="center">
                        <Text as="h1" fontSize="5xl">
                            Say hello!
                            <Text as="span" role="img" aria-label="Waving hand emoji" marginX={2}>
                            👋
                        </Text>
                        </Text>
                    </Heading>
                </Box>
                <Grid
                    templateColumns={{base: "repeat(1, minmax(0, 1fr))", md: "repeat(2, minmax(0, 1fr))"}}
                    gridColumnGap={8}
                    placeContent="center"
                >
                    <Box as="div"
                        display={{base: "none", md: "block"}}
                    >
                        <Image 
                            boxSize={96}
                            marginTop={10}
                            src={sayHelloAsset.contentfulAsset.file.url}
                        />
                    </Box>
                    <Box as="div"
                        display={{base: "block", md: "none"}}
                    >
                        <Heading>
                            <Text as="h1" fontSize="5xl">
                                Say hello!
                                <Text as="span" role="img" aria-label="Waving hand emoji" marginX={2}>
                                👋
                            </Text>
                            </Text>
    
                        </Heading>
                    </Box>

                    <VStack spacing={8} as="form" name="contact" method="post" action="/woohoo" data-netlify="true" data-netlify-honeypot="bot-field"
                    onSubmit={handleSubmit}
                    >
                        <input type="hidden" name="form-name" value="contact" />
                        <FormControl id="name" onChange={handleChange}>
                            <FormLabel>Your name</FormLabel>
                            <Input name="name" type="text" required/>
                        </FormControl>
                        <FormControl id="email" onChange={handleChange}>
                            <FormLabel>Your email</FormLabel>
                            <Input name="email" type="email" required/>
                        </FormControl>
                        <FormControl id="message" onChange={handleChange}>
                            <FormLabel>Your message</FormLabel>
                            <Textarea
                                placeholder="Your message"
                                size="sm"
                                resize="none"
                                name="message"
                                required
                            />
                        </FormControl>
                        <Button type="submit" alignSelf="end">Send</Button>
                    </VStack>
                </Grid>
            </Flex>
      
        </Layout>
    )
} 

export default SayHello
